import React, { useRef, useState, useEffect } from 'react'
import Layout from "../components/layout"
import { css } from "@emotion/core"
import { graphql } from "gatsby"
import { wrapper, TitleHeader, IconButton, IconButtonBtn } from "../components/defaults/styles/elements"
import mq from "../components/defaults/styles/mediaquerys"
import { color } from "../components/defaults/styles/theme"
import Link from '../components/link'
import ReactPlayer from "react-player"
import { Helmet } from "react-helmet"
import PlayIcon from '../components/defaults/assets/svg/play.svg'
import Globe from '../components/defaults/assets/svg/globe.svg'
import SkipForward from '../components/defaults/assets/svg/skipForward.svg'
import Smartphone from '../components/defaults/assets/svg/smartphone.svg'
import SoundX from '../components/defaults/assets/svg/soundx.svg'
import Speaker from '../components/defaults/assets/svg/speaker.svg'
import SubNav from '../components/main/sub_nav'
import livedata from "../data/raw/livedata.json"
import PressFooter from "../components/pressfooter"

const seo = {
  frontmatter: {
    title: "On Air",
  },
}

const ThreeQjs = ({id}) => {
  useEffect(() => {
    const playerjs = document.createElement("script");
    const playout = document.createElement("script");
    // console.log(
    //   "https://playout.3qsdn.com/" +
    //     id +
    //     "?js=true&container=sdnPlayer_VbWcqYHFBDdTtgM&layout=responsive"
    // )
    playerjs.async = false
    playout.async = false

    playerjs.src = "https://playout.3qsdn.com/player/js/sdnplayer.js"
    playout.src = `https://playout.3qsdn.com/${id}?js=true&container=sdnPlayer_VbWcqYHFBDdTtgM&layout=responsive`
    // playout.src =
    //   "https://playout.3qsdn.com/4af68ccf-d6f0-11ea-b206-0cc47a188158?js=true&container=sdnPlayer_VbWcqYHFBDdTtgM&layout=responsive"

    const body = document.body;

    body.appendChild(playerjs).appendChild(playout)

    return () => {
      document.body.removeChild(playerjs).removeChild(playout)
    }
  }, [id])

  return (
    <>
      {/* <Helmet>
        <script
          type="text/javascript"
          src="https://playout.3qsdn.com/player/js/sdnplayer.js"
        ></script>
        <script
          type="text/javascript"
          src="https://playout.3qsdn.com/4af68ccf-d6f0-11ea-b206-0cc47a188158?js=true&container=sdnPlayer_VbWcqYHFBDdTtgM&layout=responsive"
        ></script>
      </Helmet> */}
      <div
        id="sdnPlayer_VbWcqYHFBDdTtgM"
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        `}
      ></div>
    </>
  )
}

const ThreeQiframe = ({id}) => {
  return (
    <>
      <iframe
        id="sdn"
        title="3Q SDN"
        src={`https://playout.3qsdn.com/${id}`}
        frameBorder="0"
        scrolling="no"
        allowFullScreen
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        `}
      ></iframe>
    </>
  )
}

const HLS = ({refPlayer, muted, url}) => {

  return (
    <>
      <Helmet>
        <script src="https://cdn.jsdelivr.net/npm/hls.js@latest"></script>
      </Helmet>
      <ReactPlayer
        url={url}
        controls
        muted={muted}
        playing
        ref={refPlayer}
        width="100%"
        height="100%"
        css={css`
          position: absolute;
          top: 0;
          left: 0;
        `}
      />
    </>
  )
}


const InfoItem = ({title, content}) => {
  return(
    <>
      <li css={css`
        display: flex;
        border-bottom: 1px solid white;
        justify-content: space-between;
        margin: 0;
        line-height: 1em;
        padding: 1em 0.5em;
        p {
          margin-bottom: 0;
        }
      `}>
        <div>
          <p css={css`
            font-weight: 700;
          `}>{title}</p>
        </div>
        <div>
          <p>{content}</p>
        </div>
      </li>
    </>
  )
}

const Live = ({data}) => {
  const refPlayer = useRef(null)
  const [muted, setMuted] = useState(true)

  const handleSound = () => {
    setMuted(muted => !muted)
  }

  const handleLive = () => {
    const endDuration = refPlayer.current.getDuration()
    refPlayer.current.seekTo(endDuration - 1, "seconds")
  }

  return (
    <Layout dark seo={seo}>
      <section
        css={css`
          background: ${color.main_dark};
          min-height: 100vh;
          padding-bottom: 8em;
        `}
      >
        <TitleHeader
          dark
          transparent
          title="Live Stream"
          icon={<PlayIcon />}
          subtitle='<p><b>September 23rd, 2020 & September 24th, 2020</b></br>In case you encounter any issues with the live stream please use the buttons below to switch to another streaming provider/service or get in touch with the Plug & Play Germany GmbH team via the "Report a problem" button below.</p>'
        />

        <div css={wrapper}>
          <SubNav
            nav_data={livedata}
            legende="<p>Pick your preferred streaming provider <i>– all show the same content</i>.</p>"
          />
        </div>

        <div
          css={[
            wrapper,
            css`
              margin-top: 2em;
            `,
          ]}
        >
          <div
            css={css`
              max-width: 60rem;
              margin: 0 auto;
              ${mq[3]} {
                max-width: unset;
              }
            `}
          >
            <div
              css={css`
                position: relative;
                padding-bottom: 56.25%;
                padding-top: 0;
                height: 0;
                overflow: hidden;
              `}
            >
              {data.livedataJson.player === "3Qjs" ? (
                <ThreeQjs id={data.livedataJson.srcId} />
              ) : data.livedataJson.player === "3Qiframe" ? (
                <ThreeQiframe id={data.livedataJson.srcId} />
              ) : data.livedataJson.player === "ReactPlayer" ? (
                <HLS
                  refPlayer={refPlayer}
                  muted={muted}
                  url={data.livedataJson.link_url}
                />
              ) : (
                ""
              )}
            </div>
            {data.livedataJson.player === "ReactPlayer" ? (
              <>
                <div
                  css={css`
                    position: relative;
                    z-index: 1;
                  `}
                >
                  <IconButtonBtn
                    name={`Turn ${muted ? "on" : "off"} sound`}
                    icon={muted ? <Speaker /> : <SoundX />}
                    onClick={handleSound}
                    css={[
                      css`
                        margin: 1em 0.5em 1em 0;
                      `,
                    ]}
                  />
                  <IconButtonBtn
                    name={`Skip to Live`}
                    icon={<SkipForward />}
                    onClick={handleLive}
                    css={[
                      css`
                        margin: 1em 0.5em;
                      `,
                    ]}
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </div>

          <div
            css={css`
              color: white;
              font-size: 0.8em;
              display: flex;
              margin-top: 3.5em;
              flex-direction: column;
              ${mq[1]} {
                flex-direction: row;
              }
              h2 {
                font-size: 1.25rem;
              }
            `}
          >
            <div
              css={css`
                padding-right: 5em;
                position: relative;
                z-index: 1;
                ${mq[1]} {
                  flex: 1 0 20rem;
                }
                p {
                  margin-bottom: 0.5em;
                }
              `}
            >
              <h2>Description</h2>
              <p>
                Thank you for joining us for our fully virtual EXPO 8. We are
                grateful to be able to meet in this format and celebrate open
                innovation, together. An expansive community of people have made
                this event possible and we want to send a huge shout out to
                everyone who pitched in.
              </p>
              <p>Now sit back, relax and enjoy the show!</p>
              <p
                css={css`
                  margin-top: 1.5em;
                `}
              >
                —<br />
                <b>Important:</b> Do not miss to check out the official Plug &
                Play Events app where not just all of the information about the
                two event days are published, but you‘ll also gain access to
                your individually customizable agenda as well as the Chat Area
                which allows you to directly get in touch with all of the EXPO 8
                participants.
              </p>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  > * {
                    margin-top: 1em;
                  }
                `}
              >
                <IconButton
                  name="EXPO 8 App WebView"
                  icon={<Globe />}
                  url="https://ve.attendify.com/index/jnmtdx/fd6flcx"
                />
                <IconButton
                  name="EXPO 8 App for iOS/Android"
                  icon={<Smartphone />}
                  url="https://bit.ly/pnp-app/"
                />
              </div>
            </div>
            <div
              css={css`
                max-width: 20rem;
                margin-top: 4em;
                ${mq[1]} {
                  flex: 1 0 10rem;
                  margin-top: 0;
                }
              `}
            >
              <h2>Information</h2>
              <ul
                css={css`
                  border-top: 1px solid white;
                  margin: 0;
                  width: 100%;
                `}
              >
                <InfoItem title="Stream Date" content="September 23rd, 2020" />
                <InfoItem title="Start Time" content="4:00 PM CEST" />
                <InfoItem
                  title="Provider"
                  content={data.livedataJson.provider}
                />

                <li
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 2em 0.5em;
                  `}
                >
                  <div>
                    <Link
                      to={data.livedataJson.link_url}
                      css={css`
                        border: 1px solid white;
                        border-radius: 5px;
                        padding: 0.75em 1em 0.65em;
                        color: white;
                        text-decoration: none;
                        display: block;
                        line-height: 1em;
                      `}
                    >
                      Open Stream Link
                    </Link>
                  </div>
                  <div>
                    <Link
                      to="mailto:marketing-ger@pnptc.com"
                      css={css`
                        color: white;
                        font-size: 0.85em;
                      `}
                    >
                      Report a problem
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <PressFooter theme={color.main_dark} />
    </Layout>
  )
}

export default Live;


export const query = graphql`
  query Live($id: String) {
    livedataJson(id: { eq: $id }) {
      name
      url
      provider
      link_url
      path
      player
      srcId
    }
  }
`